// extracted by mini-css-extract-plugin
export var blogPostPreview = "tag-module--blogPostPreview--Clgav";
export var cardBody = "tag-module--cardBody--0rVzc";
export var cardTitleLink = "tag-module--cardTitleLink--hV9ih";
export var headerText = "tag-module--headerText--7dYFy";
export var pageNavigationButtons = "tag-module--pageNavigationButtons--b+IG5";
export var postMetadata = "tag-module--postMetadata--TWVK3";
export var postMetadataItem = "tag-module--postMetadataItem--+oks1";
export var postTag = "tag-module--postTag--ohA7E";
export var postTagContainer = "tag-module--postTagContainer--tsfM4";
export var tagList = "tag-module--tagList--Eoxaq";
export var tagListContainer = "tag-module--tagListContainer--NqRB9";
export var tagListItem = "tag-module--tagListItem--Bfcej";